//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import query from '~/queries/newProducts.graphql';
import cmsUrls from '~/mixins/cmsUrls';

export default {
    props: ['widget', 'padding'],
    mixins: [cmsUrls],
    async fetch() {
        const {
            data: {
                products: { items },
            },
        } = await this.$apollo.query({
            query,
            variables: { skus: this.widget.fields.products },
        });
        this.products = items;
    },
    data() {
        return {
            products: [],
        };
    },
    fetchOnServer: false,
    fetchKey: 'newproducts',
};
