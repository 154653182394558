var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$fetchState.pending)?_c('div',[_vm._v("Loading")]):(_vm.$fetchState.error)?_c('div',[_vm._v("Error")]):_c('div',{staticClass:"bg-gray-100"},[_c('div',{staticClass:"container mx-auto px-8 pt-8",class:{
                'sm:pt-16': _vm.padding,
            },attrs:{"id":("product-list-" + (_vm.widget.sys.id)),"aria-labelledby":("product-list-title-" + (_vm.widget.sys.id))}},[_c('header',{staticClass:"grid grid-cols-3 gap-4"},[_c('OpusTitle',{staticClass:"text-center tracking-widest mb-7 block uppercase font-extrabold col-span-3 lg:col-span-1 lg:col-start-2",attrs:{"id":("product-list-title-" + (_vm.widget.sys.id)),"tag":"h3","size":"h5"}},[_vm._v("\n                    "+_vm._s(this.widget.fields.title)+"\n                ")]),_vm._v(" "),(this.widget.fields.link)?_c('OpusLink',{staticClass:"ml-auto hidden lg:flex",attrs:{"to":_vm.formatUrl(this.widget.fields.link.fields.url),"alt":this.widget.fields.link.fields.title,"open-new-tab":this.widget.fields.link.fields.openInNewTab}},[_vm._v("\n                    "+_vm._s(this.widget.fields.link.fields.title)+"\n                ")]):_vm._e()],1),_vm._v(" "),(_vm.products.length)?_c('Slider',{attrs:{"type":"carousel","per-view":4,"breakpoints":{
                    0: {
                        perView: 1,
                    },
                    500: {
                        perView: 1,
                    },
                    768: {
                        perView: 2,
                    },
                    1200: {
                        perView: 3,
                    },
                    1600: {
                        perView: 4,
                    },
                },"peek":{ before: 0, after: 100 },"gap":20,"bound":false},scopedSlots:_vm._u([{key:"control",fn:function(){return [_c('OpusButton',{staticClass:"mt-4",attrs:{"theme":"flush","icon-code":"chevron-left","data-glide-dir":"<","aria-label":_vm.$t('pagination.prev')}},[_vm._v("\n                        "+_vm._s(_vm.$t('pagination.prev'))+"\n                    ")]),_vm._v(" "),_c('OpusButton',{staticClass:"rotate-180",attrs:{"theme":"flush","icon-code":"chevron-left","data-glide-dir":">","aria-label":_vm.$t('pagination.next')}},[_vm._v("\n                        "+_vm._s(_vm.$t('pagination.next'))+"\n                    ")])]},proxy:true}],null,false,4022866389)},[_vm._v(" "),_vm._l((_vm.products),function(product){return _c('ProductCard',{key:product.name,staticClass:"w-full",attrs:{"product":product}})})],2):_vm._e(),_vm._v(" "),_c('div',{staticClass:"m-auto lg:hidden flex"},[(this.widget.fields.link)?_c('OpusLink',{staticClass:"m-auto text-center",attrs:{"to":_vm.formatUrl(this.widget.fields.link.fields.url),"alt":this.widget.fields.link.fields.title,"open-new-tab":this.widget.fields.link.fields.openInNewTab}},[_vm._v("\n                    "+_vm._s(this.widget.fields.link.fields.title)+"\n                ")]):_vm._e()],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }